import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CheckoutcartItem from './CheckoutcartItem';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import Loader from "../../Components/Common/Loader"
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setTotalCartAmount } from '../../slices/couponSlice';


function OrderSummery({ selectedPaymentMode }) {
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const shippingCharges = 0;
    const [cartItems, setCartItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [discountAmount, setDiscountAmount] = useState(0);
    const [couponDiscount, setCouponDiscount] = useState('');
    const { discount, discountType } = useSelector(state => state.coupon);

    useEffect(() => {
        const fetchCartItems = async () => {
            const sessionId = getSessionId();
            try {
                const response = await axios.get('https://admin.theroarfit.com/api/cart/view', {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Session-ID': sessionId,
                    },
                });
                const cartItemsData = response.data.data;
                setCartItems(cartItemsData);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching cart items:', error);
            }
        };
        fetchCartItems();
    }, []);

    useEffect(() => {
        const calculatedTotalAmount = cartItems.reduce((acc, curr) => {
            const gstpercent = curr.product?.gst;
            const calculateGstAmount = (price, gstpercent) => (price * gstpercent) / 100;
            const gstAmount = calculateGstAmount(curr.product?.sale_price, gstpercent);
            const totalItemAmountWithGST = (Number(curr.product?.sale_price) + Number(gstAmount)) * curr.quantity;
            return acc + totalItemAmountWithGST;
        }, 0);

        const calculatedTotalItems = cartItems.reduce((acc, curr) => {
            return Number(acc) + Number(curr.quantity);
        }, 0);
        setTotalAmount(calculatedTotalAmount);
        dispatch(setTotalCartAmount({
            totalAmount: Math.round(calculatedTotalAmount)
        }));
        setTotalItems(calculatedTotalItems);
    }, [cartItems]);

    // useEffect(() => {
    //     if (selectedPaymentMode === 'phonepe') {
    //         setDiscountAmount(totalAmount * 0.05);
    //     } else {
    //         setDiscountAmount(0);
    //     }
    // }, [selectedPaymentMode, totalAmount]);

    useEffect(() => {
    }, [totalAmount]);

    const getSessionId = () => {
        let sessionId = localStorage.getItem('sessionId');
        if (!sessionId) {
            sessionId = uuidv4();
            localStorage.setItem('sessionId', sessionId);
        }
        return sessionId;
    };

    useEffect(() => {
        if (discountType === 'Flat') {
            setCouponDiscount(discount || 0);
        } else if (discountType === 'Percentage') {
            const discountValue = discount || 0;
            setCouponDiscount(totalAmount * (discountValue / 100));
        } else {
            setCouponDiscount(0);
        }
    }, [discount, discountType, totalAmount]);



    return (
        <div className='h-full bg-[#eeedec] font-Quicksand'>
            {loading ? (
                <Loader />
            ) : cartItems.length > 0 ? (
                <div>
                    <div className=' justify-center flex flex-col '>
                        <div className=''>
                            {cartItems.map((cartItem, index) => (
                                <CheckoutcartItem
                                    key={index}
                                    item={cartItem.product}
                                    fullItem={cartItem}
                                    quantity={cartItem.quantity}
                                />
                            ))}
                        </div>
                        <div className='mt-1 h-full p-6 border-t-2 border-white'>
                            <div className="mb-2 flex justify-between">
                                <p className="text-gray-900 font-semibold">Total Items</p>
                                <p className="text-gray-900 font-semibold">{totalItems}</p>
                            </div>
                            <div className="mb-2 flex justify-between">
                                <p className="text-gray-900 font-semibold">Subtotal Amount</p>
                                <p className="text-gray-900 font-semibold">Rs.  {Math.round(totalAmount).toFixed(2)}</p>
                            </div>
                            {
                                discountType && discount !== 0 && (
                                    <div className="mb-2 flex justify-between">
                                        <p className="text-gray-900 font-semibold">Coupon Discount</p>
                                        <p className="text-gray-900 font-semibold">Rs.  {Math.round(couponDiscount).toFixed(2)}</p>
                                    </div>
                                )
                            }
                            {/* {
                                selectedPaymentMode === 'phonepe' && discountAmount !== 0 && (
                                    <div className="mb-2 flex justify-between">
                                        <p className="text-gray-900 font-semibold">Prepaid Discount</p>
                                        <p className="text-gray-900 font-semibold">Rs.  {Math.round(discountAmount).toFixed(2)}</p>
                                    </div>
                                )
                            } */}
                            <div className="flex justify-between">
                                <p className="text-gray-900 font-semibold">Shipping</p>
                                <div className='flex gap-2 font-semibold'>
                                    <p className=' line-through text-red-500'>Rs.150</p>
                                    <p className=''>Free</p>
                                </div>
                            </div>
                            <hr className="my-4" />
                            <div className="flex justify-between">
                                <p className="text-lg font-bold">Total Paybill</p>
                                <div>
                                    <p className="mb-1 text-lg font-bold text-right">Rs.  {Math.round((totalAmount - couponDiscount) + shippingCharges - discountAmount).toFixed(2)}</p>
                                    <p className="text-sm text-gray-700 text-right font-semibold">including GST</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='flex flex-col justify-center items-center h-[50%] md:h-full gap-3'>
                    <h1 className='text-black font-bold'>Cart Empty</h1>
                    <Link to='/'>
                        <button className='bg-black text-white font-bold py-2 px-4 rounded w-full'>Shop Now</button>
                    </Link>
                </div>
            )}
        </div>
    );
}

export default OrderSummery;
