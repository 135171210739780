import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import Loader from '../Common/Loader';
import { useDispatch, useSelector } from 'react-redux'
import { add, increaseQuantity, decreaseQuantity } from "../../slices/cartSlice"
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { CiHeart } from "react-icons/ci"
import { BiSolidHeart } from "react-icons/bi"
import Product from '../Product/Product';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { setAllWishlistItems, addWishlistItem, removeWishlistItem } from '../../slices/wishlistSlice';
import { getAllWishlistItems } from '../UserDashboard/main/UserWishlist';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FaShoppingCart } from "react-icons/fa"
import { FaShippingFast } from "react-icons/fa";
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import { RiArrowDownSLine } from "react-icons/ri";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";
import FeatureSlider from './FeatureSlider';
import { RWebShare } from "react-web-share";
import { FaShareFromSquare } from "react-icons/fa6";
import { FaCheckCircle } from "react-icons/fa";
import Review from '../Common/Review';
import { IoCloseSharp } from "react-icons/io5";
import ComboProductsOptions from './ComboProductsOptions';
import { GiConsoleController } from 'react-icons/gi';

function ImageSlider({ defaultImage, Images }) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [showMagnifier, setShowMagnifier] = useState(false);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    const { left, top, width, height } = e.currentTarget.getBoundingClientRect();
    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - top) / height) * 100;
    setPosition({ x, y });
    setCursorPosition({ x: e.pageX - left, y: e.pageY - top });
  };
  return (
    <div className='flex flex-col gap-1'>
      <div className='flex items-center gap-2'>
        <Swiper
          spaceBetween={10}
          navigation={true}
          thumbs={{ swiper: thumbsSwiper }}
          modules={[FreeMode, Navigation, Thumbs]}
          className="w-11/12  max-w-[500px] aspect-[50/47]"
        >

          <SwiperSlide
            className="img-magnifier-container"
            onMouseEnter={() => setShowMagnifier(true)}
            onMouseLeave={() => setShowMagnifier(false)}
            onMouseMove={handleMouseMove}
          >
            <img className="magnifier-img object-contain w-full h-full" loading='lazy' src={defaultImage} alt="" />

            {showMagnifier && (
              <div
                style={{
                  position: "absolute",
                  left: `${cursorPosition.x - 100}px`,
                  top: `${cursorPosition.y - 100}px`,
                  pointerEvents: "none",
                }}
              >
                <div
                  className="magnifier-image"
                  style={{
                    backgroundImage: `url(${defaultImage})`,
                    backgroundPosition: `${position.x}% ${position.y}%`,
                  }}
                />
              </div>
            )}
          </SwiperSlide>
          {
            Images.length > 0 && Images.map((image, index) => (<SwiperSlide key={index}
              className="img-magnifier-container"
              onMouseEnter={() => setShowMagnifier(true)}
              onMouseLeave={() => setShowMagnifier(false)}
              onMouseMove={handleMouseMove}>
              <img className="magnifier-img object-contain w-full h-full" loading='lazy' src={image.medium} alt="" />

              {showMagnifier && (
                <div
                  style={{
                    position: "absolute",
                    left: `${cursorPosition.x - 100}px`,
                    top: `${cursorPosition.y - 100}px`,
                    pointerEvents: "none",
                  }}
                >
                  <div
                    className="magnifier-image"
                    style={{
                      backgroundImage: `url(${image.medium})`,
                      backgroundPosition: `${position.x}% ${position.y}%`,
                    }}
                  />
                </div>
              )}
            </SwiperSlide>))
          }

        </Swiper>
      </div>
      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={10}
        slidesPerView={Images.length <= 2 ? Images.length + 1 : 3}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="w-11/12 max-w-[400px] px-5 sm:px-0"
      >
        <SwiperSlide className=''>
          <img src={defaultImage} loading='lazy' className='object-contain w-full border rounded-md h-20 object-center' alt='product img' />
        </SwiperSlide>
        {
          Images.length > 0 && Images.map((image, index) => (<SwiperSlide key={index} className=''>
            <img src={image.medium} alt="name" loading='lazy' className='object-contain w-full border rounded-md h-20 object-center' />
          </SwiperSlide>))
        }
      </Swiper>
    </div>
  );
}

const ProductDetailPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const location = useLocation();
  const { wishlistData } = useSelector(state => state.wishlist)
  const { cart } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState(null);
  const { id } = useParams()
  const [isFilled, setIsFilled] = useState(false);
  const userToken = JSON.parse(localStorage.getItem('token'));
  const [quantity, setQuantity] = useState(1);
  const navigate = useNavigate();
  const [selectedSize, setSelectedSize] = useState('');
  const [selectedColor, setSelectedColor] = useState('')
  const [notifyEmail, setNotifyEmail] = useState('');
  const [showNotifyModal, setShowNotifyModal] = useState(false);
  const [availableStock, setAvailableStock] = useState('');
  const [openIndex, setOpenIndex] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [showStickyDiv, setShowStickyDiv] = useState(false);
  const [showchartModal, setShowchartModal] = useState(false);
  const [isCombo, setIsCombo] = useState(false);
  const variations = product?.variations || [];
  const [comboSelectedOptions, setComboSelectedOptions] = useState({});
  const [comboNotifyEmail, setComboNotifyEmail] = useState('');
  const [comboProducts, setComboProducts] = useState([]);

  useEffect(() => {
    const fetchOneProductData = async (id) => {
      setLoading(true);
      try {
        const response = await axios.get(`https://admin.theroarfit.com/api/get-products/${id}`);
        const result = response.data.data;
        if (result?.is_combo === 1) {
          setIsCombo(true);
          setComboProducts(result?.combo_products)
        } else {
          setIsCombo(false);
          setComboProducts([])
        }
        setProduct(result);
      } catch (error) {
        console.log('Error occurred during fetching the product data', error);
        setProduct(null);
      } finally {
        setLoading(false);
      }
    };
    fetchOneProductData(id);
  }, [id]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const distanceFromBottom = document.documentElement.scrollHeight - window.innerHeight - scrollY;
      setShowStickyDiv(scrollY > 1000 / 2 && distanceFromBottom > 1000 / 2);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleDescriptionAccordion = () => {
    setIsOpen(!isOpen);
  };


  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const getSessionId = () => {
    let sessionId = localStorage.getItem('sessionId');
    if (!sessionId) {
      sessionId = uuidv4();
      localStorage.setItem('sessionId', sessionId);
    }
    return sessionId;
  };

  const addToCart = async () => {
    const sessionId = getSessionId();
    if (isCombo) {
      const comboSelectedProducts = getComboSelectedProducts();
      if (comboSelectedProducts.length === 0) {
        toast.error('Please select options for all combo products before adding to the cart.');
        return;
      }
      if (comboSelectedProducts.some(product => !product.selectedSize || !product.selectedColor)) {
        toast.error('Please select size and color for all combo products.');
        return;
      }

      if (comboSelectedProducts.some(product => product.availableStock <= 0)) {
        toast.error('One or more combo products are out of stock');
        return;
      }
      const payload = {
        product_id: product.id,
        quantity: quantity || 1,
        selectedCombo: comboSelectedProducts,
        isCombo: true
      };
      try {
        const toastId = toast.loading("Adding combo products to cart...");
        const response = await axios.post('https://admin.theroarfit.com/api/cart/add', payload, {
          headers: {
            'Content-Type': 'application/json',
            'X-Session-ID': sessionId,
          },
        });
        dispatch(add(response.data.data));
        toast.success("Combo products added to cart");
      } catch (error) {
        console.error('Error adding combo products to the cart:', error);
        toast.error('Error adding combo products to the cart');
      }
    } else {
      if (hasOptions) {
        if (!selectedSize || !selectedColor) {
          toast.error('Please select size, color before adding to the cart.');
          return;
        }
      }
      if (availableStock > 0) {
        const toastId = toast.loading("Loading...")
        try {
          const response = await axios.post('https://admin.theroarfit.com/api/cart/add', {
            product_id: product.id,
            quantity: quantity || 1,
            size: selectedSize,
            color: selectedColor,
          },
            {
              headers: {
                'Content-Type': 'application/json',
                'X-Session-ID': sessionId,
              },
            }
          );
          dispatch(add(response.data.data))
          toast.success("Item added to cart");
          toast.dismiss(toastId)
        } catch (error) {
          console.error('Error adding item to the cart:', error);
          toast.error('Error adding item to the cart');
          toast.dismiss(toastId)
        }
      } else {
        toast.error("Item is out of stock");
      }
    }
  }

  const updateAvailableStock = (color, size) => {
    const variation = variations.find(variant => variant.color === color);
    if (variation) {
      const sizeVariant = variation.variations.find(variant => variant.size === size);
      if (sizeVariant) {
        setAvailableStock(sizeVariant.stock);
      } else {
        setAvailableStock(0);
      }
    } else {
      setAvailableStock(0);
    }
  };

  useEffect(() => {
    if (product) {
      setImageInAction(product?.image_data?.medium || '');
      setGalleryImages(product.gallery_image_data || []);
      if (product.variations.length > 0) {
        const firstColor = product.variations[0].color;
        setSelectedColor(firstColor);
        const firstSize = product.variations[0].variations.length > 0
          ? product.variations[0].variations[0].size
          : '';
        setSelectedSize(firstSize);
        updateAvailableStock(firstColor, firstSize);
      }
    }
  }, [product]);

  const [imageInAction, setImageInAction] = useState('');
  const [galleryImages, setGalleryImages] = useState([]);
  const gstpercent = product?.gst;
  const calculateGstAmount = (price, gstpercent) => (price * gstpercent) / 100;
  const gstAmount = calculateGstAmount(product?.sale_price, gstpercent);
  const salePriceWithGst = (Number(product?.sale_price) + (gstAmount)).toFixed(2)

  const increaseItemQuantity = () => {
    if (quantity < availableStock) {
      dispatch(increaseQuantity(product.id));
      const updatedQuantity = quantity + 1;
      setQuantity(updatedQuantity);

      const qtyaddToCart = async () => {
        const sessionId = getSessionId();
        if (hasOptions) {
          if (!selectedSize || !selectedColor) {
            toast.error('Please select size, color before adding to the cart.');
            return;
          }
        }
        const toastId = toast.loading("Updating...");

        if (availableStock > 0) {
          try {
            const response = await axios.post('https://admin.theroarfit.com/api/cart/add', {
              product_id: product.id,
              quantity: updatedQuantity,
              size: selectedSize,
              color: selectedColor,
            },
              {
                headers: {
                  'Content-Type': 'application/json',
                  'X-Session-ID': sessionId,
                },
              });

            dispatch(add(product));
            toast.success("Item updated");
          } catch (error) {
            console.error('Error updating item to the cart:', error);
            toast.error('Error updating item to the cart');
          } finally {
            toast.dismiss(toastId);
          }
        } else {
          toast.error("Item is out of stock")
        }
      };

      qtyaddToCart();
    } else {
      toast.error("Cannot increase quantity beyond available stock.");
    }
  };

  const decreaseItemQuantity = () => {
    if (quantity > 1) {
      dispatch(decreaseQuantity(product.id));
      const updatedQuantity = quantity - 1;
      setQuantity(updatedQuantity);

      const qtyaddToCart = async () => {
        const sessionId = getSessionId();
        const toastId = toast.loading("Updating...");

        try {
          const response = await axios.post('https://admin.theroarfit.com/api/cart/add', {
            product_id: product.id,
            quantity: updatedQuantity,
          },
            {
              headers: {
                'Content-Type': 'application/json',
                'X-Session-ID': sessionId,
              },
            });

          dispatch(add(product));
          toast.success("Item Updated");

        } catch (error) {
          console.error('Error updating item:', error);
          toast.error('Error updating item s');
        } finally {
          toast.dismiss(toastId);
        }
      };

      qtyaddToCart();
    } else {
      toast.error("Quantity cannot be less than 1.");
    }
  };

  const addToWishlist = async () => {
    if (!userToken) {
      toast.error('Please log in first.');
      return;
    }
    const toastId = toast.loading("Loading...")
    try {
      const formData = new FormData();
      formData.append("product_id", product.id)
      const response = await axios.post(
        'https://admin.theroarfit.com/api/wishlist/add',
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userToken}`,
          }
        }
      );

      const responseData = response.data;
      // console.log("respnse", response);
      toast.success(responseData.message);
      wishlistData.length > 0 ? dispatch(addWishlistItem(response.data.data)) : getAllWishlistItems(userToken, dispatch, setAllWishlistItems);
      setIsFilled(!isFilled); // Toggle the heart icon
    } catch (error) {
      // Handle network error
      console.error('Error adding product to wishlist:', error);
      toast.error('An error occurred while adding the product to the wishlist.');
    }
    toast.dismiss(toastId)
  };

  const deleteWishlistItem = async () => {
    if (!userToken) {
      toast.error('Please log in first.');
      return;
    }

    const toastId = toast.loading('Loading...');

    try {
      const response = await axios.delete(
        `https://admin.theroarfit.com/api/wishlist/remove/${product.id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const responseData = response.data;
      // console.log('delete respnse', response);

      if (response.status === 200) {
        toast.success(responseData.message);
        setIsFilled(!isFilled);
        dispatch(removeWishlistItem(product.id));
      } else {
        console.log('Error removing product from wishlist:', responseData);
        toast.error('Failed to remove product from wishlist. Please try again.');
      }
    } catch (error) {
      // Handle network error
      console.error('Error removing product from wishlist:', error);
      toast.error('An error occurred while removing the product from the wishlist.');
    }

    toast.dismiss(toastId);
  };

  // const handleCheckout = async () => {
  //   if (isCombo) {
  //     const comboSelectedProducts = getComboSelectedProducts();
  //     if (comboSelectedProducts.length === 0) {
  //       toast.error('Please select options for all combo products before proceeding.');
  //       return;
  //     }
  //     if (comboSelectedProducts.some(product => !product.selectedSize || !product.selectedColor)) {
  //       toast.error('Please select size and color for all combo products.');
  //       return;
  //     }
  //     if (comboSelectedProducts.some(product => product.availableStock <= 0)) {
  //       toast.error('One or more combo products are out of stock.');
  //       return;
  //     }
  //     await addToCart();
  //     navigate("/checkout");
  //   } else {
  //     if (hasOptions) {
  //       if (!selectedSize || !selectedColor) {
  //         toast.error('Please select size and color before adding to the cart.');
  //         return;
  //       }

  //       const selectedVariation = product.variations.find(variation =>
  //         variation.color === selectedColor
  //       );

  //       if (selectedVariation) {
  //         const stockItem = selectedVariation.sizes.find(size =>
  //           size.size === selectedSize
  //         );

  //         if (stockItem && stockItem.stock > 0) {
  //           await addToCart();
  //           navigate("/checkout");
  //         } else {
  //           toast.error("Cannot proceed to checkout. Item is out of stock.");
  //         }
  //       } else {
  //         toast.error("Selected color is not available.");
  //       }
  //     } else {
  //       await addToCart();
  //       navigate("/checkout");
  //     }
  //   }
  // };


  // const handleCheckout = async () => {
  //   if (hasOptions) {
  //     if (!selectedSize || !selectedColor) {
  //       toast.error('Please select size and color before adding to the cart.');
  //       return;
  //     } else {
  //       const selectedVariation = product.variations.find(variation =>
  //         variation.color === selectedColor
  //       );

  //       if (selectedVariation) {
  //         const stockItem = selectedVariation.variations.find(variation =>
  //           variation.size === selectedSize
  //         );

  //         if (stockItem && stockItem.stock > 0) {
  //           await addToCart();
  //           navigate("/checkout");
  //           console.log("click3")
  //           return;
  //         }
  //       }

  //       toast.error("Cannot proceed to checkout. Item is out of stock.");
  //     }
  //   }
  // }

  const handleCheckout = async () => {
    if (!selectedSize || !selectedColor) {
      toast.error('Please select size and color before adding to the cart.');
      return;
    } else {
      const selectedVariation = product.variations.find(variation =>
        variation.color === selectedColor
      );

      if (selectedVariation) {
        const stockItem = selectedVariation.variations.find(variation =>
          variation.size === selectedSize
        );

        if (stockItem && stockItem.stock > 0) {
          await addToCart();
          navigate("/checkout");
          console.log("click3")
          return;
        }
      }
      toast.error("Cannot proceed to checkout. Item is out of stock.");
    }
  }



  const handleNotifyMeClick = () => {
    setShowNotifyModal((prevShowNotifyModal) => !prevShowNotifyModal);
  };

  const handleNotifySubmit = async () => {
    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(notifyEmail)) {
      toast.error('Please enter a valid email address.');
      return;
    }

    try {
      const response = await axios.post(
        'https://admin.theroarfit.com/api/product-notify',
        {
          email: notifyEmail,
          product_id: product.id,
        }
      );

      if (response.status === 200) {
        setShowNotifyModal(false);
        setNotifyEmail('');
        toast.success('You will be notified when the product is back in stock.');
      } else {
        toast.error('Failed to submit notification. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting notification:', error);
      toast.error('An error occurred. Please try again later.');
    }
  };

  const [categoryProducts, setCategoryProducts] = useState({ data: { products: { data: [] } } });
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 8;

  useEffect(() => {
    const fetchCategoryProducts = async () => {
      try {
        if (product?.category?.slug) {
          const response = await axios.get(`https://admin.theroarfit.com/api/categories/${product.category.slug}`);
          const categoryProductsData = response.data;
          const filteredProducts = categoryProductsData.data.products.data.filter(
            (categoryProduct) => categoryProduct.id !== product.id
          );

          setCategoryProducts({ data: { products: { data: filteredProducts } } });
        }
      } catch (error) {
        console.error('Error fetching category products:', error);
      } finally {
        setLoading(false);
      }
    };

    if (product?.category?.slug) {
      fetchCategoryProducts();
    }
  }, [product]);

  const hasOptions = product?.size && product.color;

  if (loading) {
    return <Loader />;
  }
  const totalProducts = categoryProducts.data.products.data.length;
  const totalPages = Math.ceil(totalProducts / productsPerPage);
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = categoryProducts.data.products.data.slice(indexOfFirstProduct, indexOfLastProduct);

  const handleColorChange = (color) => {
    setSelectedColor(color);
    const imageInUse = product.color_images[color];
    setImageInAction(imageInUse ? imageInUse.original : product.image_data.original);
    if (selectedSize) {
      updateAvailableStock(color, selectedSize);
    } else {
      setAvailableStock('');
    }
  };

  const handleSizeChange = (size) => {
    setSelectedSize(size);
    if (selectedColor) {
      updateAvailableStock(selectedColor, size);
    }
  };

  const handleAddToCart = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  //for combo products functionality

  const handleComboColorSelect = (productName, color) => {
    setComboSelectedOptions(prevOptions => ({
      ...prevOptions,
      [productName]: {
        ...prevOptions[productName],
        selectedColor: color,
        selectedSize: '',
        availableStock: '',
        showNotifyModal: false
      }
    }));
  };

  const handleComboSizeSelect = (productName, size) => {
    const product = comboProducts.find(p => p.name === productName);
    const colorVariation = product.variations.find(v => v.color === comboSelectedOptions[productName]?.selectedColor);
    const sizeVariant = colorVariation?.variations.find(v => v.size === size);

    setComboSelectedOptions(prevOptions => ({
      ...prevOptions,
      [productName]: {
        ...prevOptions[productName],
        selectedSize: size,
        availableStock: sizeVariant ? sizeVariant.stock : ''
      }
    }));
  };

  const handleComboNotifyClick = (productName) => {
    setComboSelectedOptions(prevOptions => ({
      ...prevOptions,
      [productName]: {
        ...prevOptions[productName],
        showNotifyModal: true
      }
    }));
  };

  const handleComboNotifySubmit = (productName) => {
    setComboSelectedOptions(prevOptions => ({
      ...prevOptions,
      [productName]: {
        ...prevOptions[productName],
        showNotifyModal: false
      }
    }));
  };

  const handleComboNotifyEmailChange = (email) => {
    setComboNotifyEmail(email);
  };

  const getComboSelectedProducts = () => {
    return Object.keys(comboSelectedOptions).map(productName => {
      const { selectedColor, selectedSize, availableStock } = comboSelectedOptions[productName];
      return {
        productName,
        selectedColor,
        selectedSize,
        availableStock
      };
    });
  };

  return (
    <>
      {
        loading ? <Loader /> :
          (
            <>
              <div className='bg-white mx-2 border px-4 mb-4 mt-4 rounded-md shadow-lg flex flex-col justify-between lg:flex-row gap-4 pb-16 sm:mx-auto max-w-7xl w-full content-start font-Quicksand'>
                <div className=''>
                  <ImageSlider Images={galleryImages} defaultImage={imageInAction} />
                </div>
                <div className='flex flex-col gap-1 sm:gap-4 lg:w-2/4 pl-4 pr-4 pt-5'>
                  <div>
                    <span className=' text-gray-500 uppercase text-base sm:text-lg'>{product?.brand?.name || "Roarfit"}</span>
                    <div className='flex gap-4'>
                      <h1 className='text-lg md:text-2xl font-bold'>{product?.name}</h1>
                      <div className='text-gray-400 hover:cursor-pointer'>
                        {isFilled ? <BiSolidHeart className='text-2xl sm:text-3xl' onClick={deleteWishlistItem} /> : <CiHeart className='text-2xl sm:text-3xl' onClick={addToWishlist} />}
                      </div>
                    </div>
                  </div>
                  <div className='flex gap-2 items-center'>
                    <div className='flex gap-2'>
                      <p className='text-lg md:text-xl align-text-bottom font-bold text-red-500 whitespace-nowrap'>
                        Rs. {salePriceWithGst}
                      </p>
                      <p className='text-lg md:text-[16px] line-through decoration-black align-text-bottom  text-gray-500 whitespace-nowrap'>
                        Rs. {product?.regular_price}
                      </p>
                    </div>
                    <div className="flex items-center justify-start sm:justify-center h-full">
                      <p className="text-white px-2 rounded-2xl bg-red-600 font-bold text-center text-[12px] whitespace-nowrap ">
                        Saving Rs. {(product?.regular_price - salePriceWithGst).toFixed(2)}
                      </p>
                    </div>
                  </div>
                  <p className=' whitespace-nowrap text-sm text-gray-500 sm:text-base text-left italic font-semibold'>Tax included.Shipping calculated at checkout.</p>
                  <hr />
                  {isCombo ? (
                    <ComboProductsOptions
                      comboProducts={comboProducts}
                      comboSelectedOptions={comboSelectedOptions}
                      comboNotifyEmail={comboNotifyEmail}
                      onComboColorSelect={handleComboColorSelect}
                      onComboSizeSelect={handleComboSizeSelect}
                      onComboNotifyClick={handleComboNotifyClick}
                      onComboNotifySubmit={handleComboNotifySubmit}
                      onComboNotifyEmailChange={handleComboNotifyEmailChange}
                    />
                  ) : (
                    <>
                      <div className="flex flex-col gap-2 max-w-full w-11/12 mx-auto mt-4">
                        {variations.length > 0 && (
                          <div className="text-black text-sm mb-2 flex flex-col gap-2">
                            <div>
                              <label htmlFor="colorDropdown" className="block mb-2 text-sm font-semibold text-black">
                                Color: <span className="text-[10px]">{selectedColor}</span>
                              </label>
                              <div className="flex flex-wrap gap-2">
                                {variations.map((variation, index) => (
                                  <label
                                    key={index}
                                    className={`border inline-flex items-center cursor-pointer rounded-lg p-2 ${selectedColor === variation.color ? 'bg-black text-white' : 'bg-white text-black'
                                      }`}
                                  >
                                    <input
                                      type="radio"
                                      className="hidden"
                                      name="color"
                                      value={variation.color}
                                      checked={selectedColor === variation.color}
                                      onChange={(e) => handleColorChange(e.target.value)}
                                    />
                                    <span className="text-sm">{variation.color || 'N/A'}</span>
                                  </label>
                                ))}
                              </div>
                            </div>
                            <div>
                              <label htmlFor="sizeDropdown" className="block mb-1 text-sm font-semibold text-black">
                                Size: <span className="text-[10px]">{selectedSize}</span>
                              </label>
                              <div className="flex flex-wrap gap-2">
                                {variations
                                  .find((variant) => variant.color === selectedColor)
                                  ?.variations.map((sizeVariant, index) => (
                                    <label
                                      key={index}
                                      className={`border inline-flex items-center cursor-pointer rounded-lg p-2 ${selectedSize === sizeVariant.size ? 'bg-black text-white' : 'bg-white text-black'
                                        }`}
                                    >
                                      <input
                                        type="radio"
                                        className="hidden"
                                        name="size"
                                        value={sizeVariant.size}
                                        checked={selectedSize === sizeVariant.size}
                                        onChange={(e) => handleSizeChange(e.target.value)}
                                      />
                                      <span className="ml-2 text-sm">{sizeVariant.size}</span>
                                    </label>
                                  ))}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="flex gap-2 items-center">
                        {availableStock === '' ? (
                          <div className="font-semibold flex gap-2 justify-between items-center">
                            <FaCheckCircle className="text-green-600" />
                            Available Stock: <span>First choose size</span>
                          </div>
                        ) : availableStock === '0' ? (
                          <div className="flex gap-1">
                            <p className="text-red-500">Out of stock</p>
                            <button
                              className="text-black font-bold"
                              onClick={handleNotifyMeClick}
                            >
                              Notify me
                            </button>
                          </div>
                        ) : (
                          <div className="font-semibold flex gap-2 justify-between items-center">
                            <FaCheckCircle className="text-green-600" />
                            Available Stock: {availableStock}
                          </div>
                        )}
                      </div>
                      {showNotifyModal && (
                        <div className="modal border p-2 rounded-md bg-gray-100">
                          <div className="modal-content">
                            <p>You will be notified by email when the product is back in stock.</p>
                            <div className="flex flex-col gap-2 mt-2">
                              <input
                                type="email"
                                placeholder="Enter your email"
                                value={notifyEmail}
                                onChange={(e) => setNotifyEmail(e.target.value)}
                                className="focus:outline-none border rounded-md px-2"
                              />
                              <div className="flex gap-2">
                                <button
                                  onClick={handleNotifyMeClick}
                                  className="bg-black text-white font-bold text-xs px-4 py-[0.35rem] rounded shadow outline-none focus:outline-none mb-1 mt-2 sm:mt-0"
                                >
                                  Cancel
                                </button>
                                <button
                                  onClick={handleNotifySubmit}
                                  className="bg-black text-white font-bold text-xs px-4 py-[0.35rem] rounded shadow outline-none focus:outline-none mb-1 mt-2 sm:mt-0"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  <div className='flex md:items-center border-gray-100 gap-2 md:gap-6 flex-col md:flex-row'>
                    <div className="flex gap-1 sm:gap-2">
                      <div className='font-semibold'>Quantity</div>
                      <div className='flex items-center border rounded-full bg-gray-100 max-w-[200px]'>
                        <button onClick={() => { decreaseItemQuantity(quantity) }} className='ml-2 cursor-pointer flex items-center justify-center py-5 px-8 duration-100' style={{ width: '30px', height: '30px' }}>
                          <p className='text-4xl'>-</p>
                        </button>
                        <p className="h-10 w-12 text-center text-xs outline-none pt-3">{quantity}</p>
                        <button onClick={() => { increaseItemQuantity(quantity) }} className='cursor-pointe flex items-center justify-center py-5 px-8 duration-100' style={{ width: '30px', height: '30px' }}>
                          <p className='text-2xl'>+</p>
                        </button>
                      </div>
                    </div>
                    <div className='flex gap-1 sm:gap-2 items-center'>
                      <p className='font-semibold'>For bulk orders</p>
                      <p className='text-blue-700'><Link to={"/contact-us"}>Contact Us</Link></p>
                    </div>
                  </div>
                  <div className='flex flex-col sm:flex-row items-start gap-4'>
                    {

                      cart.some((p) => p.id === product.id) ?
                        (
                          <Link className="bg-black text-white font-semibold py-3 px-16 rounded-xl h-full w-11/12 sm:w-full flex gap-2 items-center justify-center" to={"/cart"}>View Cart <span><FaShoppingCart /></span></Link>
                        ) :
                        (<button
                          className="border border-black ease-in-out duration-300 bg-white text-black hover:bg-black hover:text-white font-semibold py-3 px-16 rounded-full h-full w-11/12 sm:w-full"
                          onClick={addToCart}>
                          Add to Cart
                        </button>)
                    }
                    <button className='bg-red-500 border text-white font-semibold py-3 px-16  rounded-full h-full w-11/12 sm:w-full' onClick={handleCheckout}>Buy Now</button>
                  </div>
                  <div className='border rounded-md px-2 bg-slate-100 mt-2'>
                    <div className="flex flex-col justify-center items-center py-6">
                      <div className="text-2xl text-black flex gap-2 justify-between items-center">
                        <  FaShippingFast />
                        <p className="text-xl">Shipping Offers</p>
                      </div>
                      <p className="text-normal  mt-2">Free Shipping Available</p>
                    </div>
                  </div>
                  <div className="border-t mt-2">
                    <div className="flex justify-between items-center p-2 cursor-pointer" onClick={toggleDescriptionAccordion}>
                      <h2 className="text-lg font-semibold">{"About this item"}</h2>
                      <svg
                        className={`w-6 h-6 transition-transform transform bg-gray-200 rounded-full px-1 ${isOpen ? 'rotate-180' : 'rotate-0'}`}
                        fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                      </svg>
                    </div>
                    <div
                      className={`overflow-hidden transition-max-height duration-300 ${isOpen ? ' max-h-full' : 'max-h-0'}`}
                      aria-hidden={!isOpen}
                    >
                      <div className="p-4 border-t border-b border-gray-200" dangerouslySetInnerHTML={{ __html: product?.description }} />
                    </div>
                  </div>
                  <div>
                    <RWebShare
                      data={{
                        text: "Roarfit : Commit to be fit",
                        url: location.pathname,
                        title: "Roarfit",
                      }}
                    >
                      <button className='flex justify-between items-center gap-2'> <FaShareFromSquare className='text-blue-700' /> <span>Share</span></button>
                    </RWebShare>
                  </div>
                </div>
              </div>

              {showStickyDiv && (
                <>
                  <div className="flex justify-between items-center border px-0 sm:px-2 w-[500px] rounded-md fixed bottom-[20px]  right-5 sm:right-[50px] z-10 bg-white">
                    <img src={imageInAction} alt={product.name} loading='lazy' className="w-24 h-24 mb-2 hidden sm:block object-contain" />
                    <div className='sm:flex flex-col gap-2 hidden'>
                      <p className="font-semibold truncate w-[200px] text-[12px]">{product.name}</p>
                      <div className='flex gap-2 text-[12px]'>
                        <p className='align-text-bottom font-bold text-red-500'>
                          Rs. {salePriceWithGst}
                        </p>
                        <p className='line-through decoration-black align-text-bottom font-bold text-gray-500'>
                          Rs. {product?.regular_price}
                        </p>
                      </div>
                    </div>
                    <button onClick={handleAddToCart} className="bg-red-600 text-white px-2 py-1 rounded-md sm:rounded-full hover:bg-red-700 text-[12px] hidden sm:block">Add to Cart</button>
                  </div>

                  <div className='flex justify-center items-center'>
                    <button onClick={handleAddToCart} className="bg-red-600 text-white px-2 py-3 rounded-full hover:bg-red-700 text-[14px]  w-11/12  sm:hidden fixed bottom-[20px] z-10">Add to Cart</button>
                  </div>
                </>
              )}
            </>
          )
      }
      <div>
        <Review product={product} />
      </div>

      {
        categoryProducts.data?.products?.data?.length > 0 &&
        (
          <div className='pb-12 lg:max-w-7xl mx-auto w-11/12 flex flex-col justify-evenly mt-4'>
            <p className='text-start text-sm sm:text-2xl font-Quicksand font-semibold'>You may also like these products</p>
            <div className="max-w-7xl mx-auto grid gap-2 grid-cols-2 md:gap-4 sm:grid-cols-3 lg:grid-cols-4 mt-4">
              {currentProducts.map((relatedProduct) => (
                <Product key={relatedProduct.id} product={relatedProduct} />
              ))}
            </div>
            <div className="flex items-center justify-center  mt-4 w-full font-Quicksand">
              <button
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={currentPage === 1}
                className="mr-2 px-4 py-2 bg-white border hover:cursor-pointer text-gray-400"
              >
                {"<"}
              </button>
              <p className='mx-1 text-gray-400'>
                Page {currentPage} of {totalPages}
              </p>
              <button
                className="ml-2 px-4 py-2 bg-white border hover:cursor-pointer text-gray-400"
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={indexOfLastProduct >= totalProducts}
              >
                {">"}
              </button>
            </div>
          </div>
        )
      }
      <FeatureSlider />
      {product?.faqs && product.faqs.some(faq => faq.question !== null || faq.answer !== null) ? (
        <div className="max-w-7xl w-full mx-2 sm:mx-auto border rounded-md px-4 pb-4 mb-4">
          <div className="rounded-2xl bg-white">
            <p className='text-center text-lg md:text-2xl py-4 w-full'>Frequently Asked Questions (FAQ):</p>
            {product?.faqs.map((faq, index) => (
              <div key={index}>
                <button
                  onClick={() => toggleAccordion(index)}
                  className="border justify-between bg-slate-100 rounded-md px-4 py-4 flex w-full gap-2 text-[16px] mb-2 text-left text-gray-500 font-medium"
                >
                  <div className='font-bold text-gray-700'> {faq.question} </div>
                  {openIndex === index ? <MdOutlineKeyboardArrowUp className="h-5 w-5 rounded-full bg-gray-300" /> : <RiArrowDownSLine className="h-5 w-5 rounded-full bg-gray-200" />}
                </button>
                <div
                  className={`overflow-hidden  transition-height ${openIndex === index ? 'h-auto' : 'h-0'}`}
                  style={{ maxHeight: openIndex === index ? '1000px' : '0px' }}
                >
                  <div className="border bg-slate-50 rounded-md px-4 py-4 text-gray-700 font-medium flex w-full justify-between mb-2 text-left text-sm">
                    {faq.answer}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
export default ProductDetailPage;
