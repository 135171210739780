import React, { useState } from 'react'
import logo from "../../Assets/logo.png"
import { Link, NavLink } from 'react-router-dom';
import Dropdown from './Dropdown';
import { FaShoppingCart } from 'react-icons/fa';
import { useSelector } from 'react-redux'
import CategoryDropdown from './CategoryDropdown';
import { BsPersonFill } from "react-icons/bs"
import { BsFillBagHeartFill } from "react-icons/bs"
import { BiSearchAlt2 } from "react-icons/bi"
import toast from 'react-hot-toast';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';




const Navbar = () => {
  const { token } = useSelector((state) => state.auth);
  const [navbar, setNavbar] = useState(false);
  const { cart } = useSelector((state) => state);
  const { wishlist } = useSelector((state) => state);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();


  // const [data, setData] = useState(null);
  // const [loading, setLoading] = useState(true);
  // console.log("wishlist legnht",wishlist.wishlistData.length)
  // useEffect(() => {
  //   const apiUrl = 'https://admin.theroarfit.com/api/website-settings';
  //   axios.get(apiUrl)
  //     .then(response => {
  //       setData(response.data);
  //       setLoading(false);
  //       // console.log("response of website-settings", response);
  //     })
  //     .catch(error => {
  //       console.error('Error fetching wesite-settings data:', error);
  //       setLoading(false);
  //     });
  // }, []);

  const closeNavbar = () => {
    setNavbar(false);
  };

  const userToken = JSON.parse(localStorage.getItem('token'));

  const handleWishlistClick = () => {
    if (!userToken) {
      // User is not logged in, show login first toast
      toast.error('Please log in first.');
    }
  };

  const handleSearchQueryChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchButtonClick = () => {
    if (searchQuery.trim() !== '') {
      // console.log("search query", searchQuery)
      const apiUrl = `https://admin.theroarfit.com/api/search-products/${searchQuery}`;
      axios.get(apiUrl)
        .then(response => {
          const searchResults = response.data.data;
          // Navigate to the SearchResultsPage with the search results
          navigate('/search-results', { state: { searchResults } });
          // console.log('Search Results:', response.data);
          // Clear the search box
          setSearchQuery('');
          closeNavbar();
        })
        .catch(error => {
          console.error('Error fetching search results:', error);
        });
    } else {
      // Provide feedback to the user that the search query is empty.
      console.log('Please enter a search query.');
      toast.error('Please enter a search query.')
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearchButtonClick();
    }
  };

  return (
    <div className='shadow sticky top-0 z-50 w-full'>
      <div className='flex items-center justify-center h-full bg-black' >  <p className='p-2 text-white text-xs sm:text-base text-center md:text-lg font-Quicksand'>Festive sale is live. Flat 15% off on minimum order value is 2000 use Code FESTIVAL15</p> </div>
      <nav className="w-full bg-gradient-to-b bg-white shadow">
        <div className="justify-between w-11/12 mx-auto lg:max-w-7xl md:items-center md:flex">
          <div>
            <div className="flex items-center justify-between py-2 md:block">
              <Link to="/">
                {/* {data && data.data.setting && (<img src={data.data.setting.logo_dark.original} alt="logo" className='h-12 md:h-16 text-blue-800' /> )} */}
                <img src={logo} alt="logo" loading='lazy' className='w-[156px] h-[56px] mb-2' />
              </Link>
              <div className="md:hidden flex gap-1 items-center justify-center flex-row-reverse ">
                <button
                  className="mt-1 p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                  onClick={() => setNavbar(!navbar)}
                >
                  {navbar ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 text-black"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 text-black"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  )}
                </button>
                {/* <Link to={"/userdashboard/wishlist"}><BsFillBagHeartFill className='text-xl' /></Link> */}
                <Link to="/cart" >
                  <div className="relative mt-1">
                    < FaShoppingCart className="text-2xl text-black" />
                    {
                      cart.length > 0 &&
                      <span
                        className="absolute -top-1 -right-2 bg-black text-xs w-5 h-5 flex 
                             justify-center items-center animate-bounce rounded-full text-white"
                      >{cart.length}</span>
                    }
                  </div>
                </Link>
                <div className='flex items-center md:hidden'>
                  {
                    token === null && (
                      <Link
                        to="/login"
                        className="inline-block w-fit"
                        onClick={closeNavbar}
                      >
                        <BsPersonFill className='text-black text-[1.75rem]' />
                      </Link>
                    )
                  }
                  {
                    token !== null && (
                      <Dropdown />
                    )
                  }
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 ${navbar ? "block" : "hidden"
                }`}
            >
              <ul className="items-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0 text-black ">
                <li className="ml-3 font-Quicksand font-semibold">
                  <NavLink to="/" onClick={closeNavbar}>Home</NavLink>
                </li>
                <li className="ml-3 font-Quicksand">
                  {/* <NavLink onClick={closeNavbar} className={"font-Quicksand"}></NavLink> */}
                  <CategoryDropdown />
                </li>
                <li className="ml-3 font-Quicksand font-semibold">
                  {/* <NavLink to="/productpage"  onClick={closeNavbar}>Products</NavLink> */}
                  <NavLink to="/about-us" onClick={closeNavbar}>About Us</NavLink>
                </li>
                <li className="ml-3 font-Quicksand font-semibold">
                  <NavLink to="/contact-us" onClick={closeNavbar}>Contact Us</NavLink>
                </li>
                <li className="ml-3 font-Quicksand font-semibold">
                  <NavLink to="/ordertracking" onClick={closeNavbar}>Track Your Order</NavLink>
                </li>
              </ul>

              <div className="mt-3 justify-between flex items-center md:hidden ml-3">
                <div className="relative my-1">
                  <input
                    type="text"
                    className="shadow rounded border p-1 px-2 outline-none font-Quicksand w-[95%] text-sm"
                    placeholder="Search by name..."
                    value={searchQuery}
                    onChange={handleSearchQueryChange}
                    onKeyDown={handleKeyPress}
                  />
                  <div className="absolute top-2 right-3 cursor-pointer" onClick={handleSearchButtonClick}>
                    <BiSearchAlt2 />
                  </div>
                </div>
                {/* <div className='flex items-center md:hidden mr-2'>
                  {
                    token === null && (
                      <Link
                        to="/login"
                        className="inline-block w-fit"
                        onClick={closeNavbar}
                      >
                        <BsPersonFill className='text-black text-2xl' />
                      </Link>
                    )
                  }
                  {
                    token !== null && (
                      <Dropdown />
                    )
                  }
                </div> */}
                {userToken ? (
                  <Link to="/userdashboard/wishlist" className='relative'>
                    <BsFillBagHeartFill className='text-[1.75rem]' />
                    {
                      wishlist.wishlistData.length > 0 &&
                      <span
                        className="absolute -top-1 -right-2 bg-black text-xs w-5 h-5 flex 
                             justify-center items-center animate-bounce rounded-full text-white"
                      >{wishlist.wishlistData.length}</span>
                    }
                  </Link>
                ) : (
                  <button onClick={handleWishlistClick}>
                    <BsFillBagHeartFill className='text-[1.75rem]' />
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="hidden md:flex md:items-center">
            <div className="relative my-1">
              <input
                type="text"
                className="shadow rounded border p-1 px-2 outline-none font-Quicksand w-[95%] text-sm"
                placeholder="Search by name..."
                value={searchQuery}
                onChange={handleSearchQueryChange}
                onKeyDown={handleKeyPress}
              />
              <div className="absolute top-2 right-3 cursor-pointer" onClick={handleSearchButtonClick}>
                <BiSearchAlt2 />
              </div>
            </div>
            <div className='flex items-center justify-between gap-1'>
              {
                token === null && (
                  <Link
                    to="/login"
                    className=""
                    onClick={closeNavbar}
                  >
                    <BsPersonFill className='text-black text-[1.75rem]' />
                  </Link>
                )
              }
              {
                userToken ? (
                  <Link to="/userdashboard/wishlist" className='relative'>
                    <BsFillBagHeartFill className='text-[1.75rem]' />
                    {
                      wishlist.wishlistData.length > 0 &&
                      <span
                        className="absolute -top-1 -right-2 bg-black text-xs w-5 h-5 flex 
                        justify-center items-center animate-bounce rounded-full text-white"
                      >{wishlist.wishlistData.length}</span>
                    }
                  </Link>
                ) : (
                  <button onClick={handleWishlistClick}>
                    <BsFillBagHeartFill className='text-[1.5rem]' />
                  </button>
                )
              }
              {
                token !== null && (

                  <Dropdown />

                )
              }
              <Link to="/cart" >
                <div className="relative">
                  < FaShoppingCart className="text-[1.5rem] text-black" />
                  {
                    cart.length > 0 &&
                    <span
                      className="absolute -top-1 -right-2 bg-black text-xs w-5 h-5 flex 
                             justify-center items-center animate-bounce rounded-full text-white"
                    >{cart.length}</span>
                  }
                </div>
              </Link>
            </div>
          </div>
        </div>
      </nav >
    </div >
  )
}

export default Navbar