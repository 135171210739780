import React, { useEffect } from 'react';

const ShippingPolicyPage = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <div className="container mx-auto w-11/12 max-w-7xl pt-5 mb-12 p-4">
      <h1 className="text-3xl font-bold mb-4">Shipping Policy</h1>
      <p className="mb-4">
        Our shipping policy outlines the details regarding the shipment of gym equipment and related products.
      </p>

      <ol className="list-decimal pl-4">
        <h2 className='text-lg font-bold'># Domestic Shipping:</h2>
        <li className="mb-2 ">
          <p className='font-bold'>Processing Time:</p>
          <ul>
            <li>All domestic orders are processed within [1-3] business days, excluding weekends and holidays. Orders placed after [2 PM] will be processed the next business day.
            </li>
          </ul>
        </li>
        <li className="mb-2">
          <p className='font-bold'>Shipping Duration:</p>
          <ul>
            <li>Once processed, domestic shipments typically take [2-5] business days to reach their destination, depending on the shipping method selected and the delivery location.
            </li>
          </ul>
        </li>
        <li className="mb-2">
          <p className='font-bold'>Shipping Costs:</p>
          <ul>
            <li>Standard shipping is [FREE] for all orders above [₹500]. For orders below this amount, a flat rate of [₹50] will be charged.
            </li>
          </ul>
        </li>
        <li className="mb-2">
          <p className='font-bold'>Shipping Partners:</p>
          <ul>
            <li>
              We partner with reliable courier services like [Blue Dart, FedEx, India Post] to ensure timely and safe delivery of your products.
            </li>
          </ul>
        </li>
        <li className="mb-2">
          <p className='font-bold'>Order Tracking:</p>
          <ul>
            <li>
              You will receive a tracking number via email/SMS once your order is shipped. You can track your order on our [tracking page/website] or through the courier partner’s website.
            </li>
          </ul>
        </li>
        <li className="mb-2">
          <p className='font-bold'>Delayed or Lost Shipments:</p>
          <ul>
            <li>
              If your order is delayed beyond the expected delivery time, please contact our customer support at [theroarfit@gmail.com]. In case of lost shipments, a full refund or replacement will be issued as per our discretion.
            </li>
          </ul>
        </li>
      </ol>
      <ol className="list-decimal pl-4">
        <h2 className='text-lg font-bold mt-4'># International Shipping:</h2>
        <li className="mb-2">
          <p className='font-bold'>Processing Time:</p>
          <ul>
            <li>
              International orders are processed within [3-5] business days, excluding weekends and holidays.
            </li>
          </ul>
        </li>
        <li className="mb-2">
          <p className='font-bold'>Shipping Duration:</p>
          <ul>
            <li>
              Delivery times for international shipments vary by destination and can range from [7-21] business days. Factors such as customs clearance and shipping carrier delays may affect delivery times.
            </li>
          </ul>
        </li>
        <li className="mb-2">
          <p className='font-bold'>Shipping Costs:</p>
          <ul>
            <li>  Shipping costs for international orders are calculated at checkout based on the weight of the items and destination. Additional customs duties, taxes, and fees may apply and are the responsibility of the customer.
            </li>
          </ul>
        </li>
        <li className="mb-2">
          <p className='font-bold'>Customs and Import Duties:</p>
          <ul>
            <li>
              Customers are responsible for any customs or import duties levied once the package reaches their destination country. Delays due to customs are beyond our control.
            </li>
          </ul>
        </li>
      </ol>
    </div>
  );
};

export default ShippingPolicyPage;
